import * as React from "react"
import  EventCard from "../components/EventCard";
import GalleryCard from '../components/GalleryCard';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Box from "@material-ui/core/Box"
import { StaticImage } from "gatsby-plugin-image"
import Backdrop from '@material-ui/core/Backdrop';
import Carousel from '../components/Carousel'
import { loadStripe } from "@stripe/stripe-js"
import EventData from '../content/events.json';
import { formatEventHours } from '../components/EventCard';
import { generateEventPageUrl } from '../utils';
import Modal from '@material-ui/core/Modal';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { navigate, Link, useStaticQuery } from "gatsby"
import { getSourceSet } from '../utils';
import { Card, CardContent} from '@material-ui/core';

const listItem = {
  paddingLeft: '0px',
   boxSizing: 'content-box',
   width: 'auto',
   marginBottom: '0px'
}

// let stripePromise;
// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe("pk_test_51IoWINKsaoMELY1RD742SfUVdq3IxJiFfKimhuN5XqQKyeOSixQMzFtzBCsDtUKdusHHDONZn94BrMHJCPmtuBqx00o52xNrHx")
//   }
//   return stripePromise
// }
const highlightDetails = [
  {
    details: 'Coaches Enzo Lulushi and Kristen Falcinelli running a defensive drill during the all skills clinic at Olney Manor Park, Maryland',
    date: 'May 23 2021'
  },
  {
    details: 'One of the winning doubles pairs from the Queen of The Court competition round during the all skills clinic at Olney Manor Park, Maryland',
    date: 'May 23 2021'
  },
  {
    details: 'Winning attack on the beach court from the all skills clinic at Olney Manor Park, Maryland',
    date: 'May 23 2021'
  },
  {
    details: 'Coach Kelly Hughes receiving a serve in competitive doubles drills at Olney Manor Park, Maryland',
    date: 'May 23 2021'
  },
  {
    details: 'Ball control drill during the all skills clinic at Olney Manor Park, Maryland',
    date: 'May 23 2021'
  },
  {
    details: 'Doubles cooperative gameplay drill focusing on controlled attacking during the all skills clinic at Olney Manar Park, Maryland',
    date: 'May 23 2021'
  },
  {
    details: "Player executing a pokey off of Coach Enzo Lulushi's set in competitive doubles play at Olney Manor Park, Maryland",
    date: 'May 23 2021'
  },
  {
    details: 'Coach Kelly Hughes enjoying a day of beach volleyball in the sun and wishing everyone a good summer!',
    date: 'May 23d, 2021'
  }
]

const IndexPage = (props) => {

  const data = props.data;
  console.log(data);
  const structuredData = `
  {
    "@context": "http://schema.org",
    "@type": "SportsActivityLocation",
    "@id": "${data.site.siteMetadata.siteUrl}",
    "image": ${getSourceSet(data.orgPhoto.childImageSharp.fixed.srcSet, data.site.siteMetadata.siteUrl)},
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Mclean",
      "addressRegion": "VA",
      "postalCode":"22102",
      "streetAddress": "8300 Greensboro Drive "
    },
    "name": "Blue Crabs Volleyball",
    "telephone": "+15714820147",
    "openingHoursSpecification": 
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "08:00",
        "closes": "20:00"
      }
    ,
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "38.7716198",
      "longitude": "-77.1098972"
    },
    "url": "https://bluecrabsvolleyball.com",
    "sameAs" : ["https://www.facebook.com/bluecrabsbvb",
    "https://twitter.com/bluecrabsbvb",
    "https://www.instagram.com/bluecrabsbvb/"]
  }`;
  // console.log(structuredData);
  // const redirectToCheckout = async event => {
  //   event.preventDefault()
  //   //setLoading(true)
  //   // const stripe = await getStripe()
  //   const { error } = await stripe.redirectToCheckout({
  //     mode: "payment",
  //     lineItems: [{ price: "price_1IoaFzKsaoMELY1Rfb4WGjHR", quantity: 1 }],
  //     successUrl: `http://localhost:8000/page-2/`,
  //     cancelUrl: `http://localhost:8000/`,
  //   })
  //   if (error) {
  //     console.warn("Error:", error)
  //     //setLoading(false)
  //   }
  // }

  const [selectedHighlight, setSelectedHighlight] = React.useState(props.location.hash && props.location.hash.split('-').length == 2 ? data.highlights.edges[parseInt(props.location.hash.split('-').pop())-1].node : null);
  const currentDate = new Date();
  let top8Events = 0;
  const upcomingEvents = EventData.events.filter((event) => {
    if(top8Events < 7 && currentDate <= new Date(`2022-${event.start.month}-${event.start.day}T${event.start.hours}:${event.start.minutes}`)){
      top8Events++;
      return true
    }
    return false;
  })
  // console.log(props);
  // console.log(selectedHighlight);

  return (<Layout>
    <SEO title={selectedHighlight ? `Home | Event Highlight ${highlightDetails[parseInt(selectedHighlight.name)-1].date}` : 'Home'} 
      image={selectedHighlight && selectedHighlight.childImageSharp.resize}
      description={selectedHighlight && highlightDetails[parseInt(selectedHighlight.name)-1].details}
      structuredData={structuredData}/>
    <Grid direction="row" container wrap="no-wrap" style={{ height: '500px', position: 'relative'}}>
      <Grid item xs={10} sm={6} md={5} lg={4} xl={2} style={{position: 'relative'}}>
        <Backdrop style={{position: 'absolute', zIndex: '2', color: '#fff', display: 'block', textAlign: 'center', paddingTop: '25px', marginLeft: '25px'}} open={true}>
          <Typography variant="h4" style={{marginBottom: '15px'}}>Raise Your Game</Typography>
          <Typography variant="body" paragraph="true" style={{paddingLeft: '3vw', paddingRight: '3vw'}}>
            The premier beach volleyball program in the DMV, where athletes will be able to develop their game and significantly
            improve while having a great time. We love volleyball and are committed to passing on that love of the sport to the 
            next generation of talented players. Our goal is to enable any athlete to be able to enjoy the sport at a
            higher level, where it's more fun and full of opportunities. We strive to help kids understand the game on a deeper level so that they can also continue to grow on their own
            and eventually become their own best coach.
            <br></br><br></br>Take a look at some of our upcoming events below, and we hope to see you on the court!
          </Typography>
        </Backdrop>
      </Grid>
      <StaticImage
        src="../images/banner.jpg"
        quality={95}
        height={500}
        width={1600}
        placeholder={'TRACED_SVG'}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="USA Beach Volleyball Player"
        fadeIn={true}
        style={{height: '100%', width:"100%", position: "absolute"}}
        layout="fullWidth"
        loading="eager"
      />
    </Grid>
    <Carousel items={upcomingEvents.length} title="Upcoming Sessions">
      <List style={{display: 'flex', flexDirection: 'row'}}>
        {upcomingEvents.map((event) => {
          
          return (<ListItem style={{...listItem, paddingLeft: '1px'}}>
            <EventCard title={event.title} time={{day: event.date, hours: formatEventHours(event.start, event.end)}}
              formId={event.formId}
              location={event.location}
              ageGroups={event.ageGroups}
              image={data[event.imageKey].childImageSharp.gatsbyImageData} 
              shareUrl={props.location.origin+'/clinics/'+generateEventPageUrl(event)}
              coaches={event.coaches.map((coach) => {
                return {image: data[coach.id].childImageSharp.gatsbyImageData, label: coach.name, id: coach.id}
              })}/>
          </ListItem>)
        })}
      </List>
    </Carousel>
    {/* <Typography variant="h3" style={{marginTop: '25px', marginLeft: '50px', marginBottom: '10px', color: "#243E83"}}>Summer 2021 - That's a Wrap!</Typography>
    <Grid direction="row" container wrap="no-wrap" style={{ height: '500px', position: 'relative', justifyContent: 'flex-end'}}>
      <StaticImage
        src="../images/next_summer.jpg"
        quality={95}
        height={500}
        width={1600}
        placeholder={'TRACED_SVG'}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="USA Beach Volleyball Player"
        fadeIn={true}
        style={{height: '100%', width:"100%", position: "absolute"}}
        layout="fullWidth"
        loading="eager"
      />
      <Grid item xs={10} sm={6} md={5} lg={4} xl={2} style={{position: 'relative'}}>
        <Backdrop style={{position: 'absolute', zIndex: '2', color: '#fff', display: 'block', textAlign: 'center', paddingTop: '25px', marginLeft: '25px'}} open={true}>
          <Typography variant="h4" style={{marginBottom: '15px'}}>Thank You!</Typography>
          <Typography variant="body" paragraph="true" style={{paddingLeft: '3vw', paddingRight: '3vw'}}>
           If you had a chance to participate in our clinics and events and would like to take some time to fill
           out a few lines of feedback for us, it would really help in making the program even better for everyone
           next summer!
            <br></br><br></br><Button size="small" color="primary" variant="contained"><a href="https://formcrafts.com/a/pmhezem">Feedback Form</a></Button>
          </Typography>
        </Backdrop>
      </Grid>
    </Grid> */}
    {selectedHighlight ? 
    <Modal open={selectedHighlight!=null} onClose={() => {navigate(`/#highlight-${selectedHighlight.name}`); setSelectedHighlight(null);}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <>{parseInt(selectedHighlight.name)!=1 &&
          <IconButton style={{position: 'absolute', left: '0', zIndex: '2', color: '#F78D1F', padding: '0px'}} 
            onClick={() => { navigate(`/#highlight-${parseInt(selectedHighlight.name)-1}`); setSelectedHighlight(data.highlights.edges[parseInt(selectedHighlight.name)-2].node)}}
            >
            <NavigateBeforeIcon fontSize="large"/>
          </IconButton>
        }
        <GalleryCard 
          image={selectedHighlight.childImageSharp.gatsbyImageData} 
          shareUrl={props.location.origin+'/gallery/'+selectedHighlight.name}
          expanded={true}
          variant="popover"
          details={highlightDetails[parseInt(selectedHighlight.name)-1].details}
          date={highlightDetails[parseInt(selectedHighlight.name)-1].date}
          />
         {parseInt(selectedHighlight.name)!=data.highlights.edges.length && 
           <IconButton style={{position: 'absolute', right: '0', zIndex: '2', color: '#F78D1F', padding: '0px'}}
              onClick={() => {navigate(`/#highlight-${parseInt(selectedHighlight.name)+1}`); setSelectedHighlight(data.highlights.edges[parseInt(selectedHighlight.name)].node)}}
              ><NavigateNextIcon fontSize="large"/>
            </IconButton>}
          </>
    </Modal> :
    <Carousel items={data.highlights.edges.length} title="Recent Highlights" variant="gallery" id="highlights">
      <List style={{display: 'flex', flexDirection: 'row'}}>
        {data.highlights.edges.map((edge, index) => {
          return (<ListItem style={{...listItem, paddingLeft: '1px'}} onClick={() => { navigate(`/#highlight-${edge.node.name}`); setSelectedHighlight(edge.node);}}>
            <GalleryCard 
              image={edge.node.childImageSharp.gatsbyImageData} 
              shareUrl={props.location.origin+'/'+edge.node.relativePath}
              id={`highlight-${edge.node.name}`}
              expanded={false}
              // details={edge.node.details.context}
              // date={edge.node.details.date} this is not available from our query by default.
              />
          </ListItem>)
        })}
      </List>
    </Carousel>
    }
  </Layout>)
}

export default IndexPage

export const query = graphql`
  query {
    passing: file(relativePath: {eq: "events/passing.jpg"}) {
        ...cardImage
    }
    digging: file(relativePath: {eq: "events/digging.jpg"}) {
        ...cardImage
    }
    serving: file(relativePath: {eq: "events/serving.jpg"}) {
      ...cardImage
    }
    hitting: file(relativePath: {eq: "events/hitting.jpg"}) {
        ...cardImage
    }
    blocker: file(relativePath: {eq: "events/blocker.jpg"}) {
        ...cardImage
    }
    jump_serve: file(relativePath : {eq: "events/jump_serve.jpg"}){
      ...cardImage
    }
    spike: file(relativePath : {eq: "events/spike.jpg"}){
      ...cardImage
    }
    tournament: file(relativePath: {eq: "events/tournament.jpg"}){
      ...cardImage
    }
    kristen_falcinelli: file(relativePath: {eq: "kristen_falcinelli_profile.jpg"}) {
      ...cardImage
    }
    kelly_hughes: file(relativePath: {eq: "kelly_hughes_profile.jpg"}) {
      ...cardImage
    }
    enzo_lulushi: file(relativePath: {eq: "enzo_lulushi_profile.jpg"}) {
      ...cardImage
    }
    edina_blazevic: file(relativePath: {eq: "edina_blazevic_profile.jpg"}) {
      ...cardImage
    }
    jordan_mckinney: file(relativePath: {eq: "jordan_mckinney_profile.jpg"}) {
      ...cardImage
    }
    highlights: allFile(
      filter: {relativeDirectory: {eq: "highlights"}}
      sort: {order: ASC, fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED)
          }
          relativePath
          name
        }
      }
    }
    site: site {
      siteMetadata {
        title
        description
        author
        keywords
        siteUrl
      }
    }
    orgPhoto: file(relativePath: {eq: "orgPhoto.jpg"}) {
      childImageSharp {
        fixed {
          srcSet
        }
      }
    }
  }
`
